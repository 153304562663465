import React from 'react';
import { useRouter } from 'next/router';

export default function HomePage() {
  const router = useRouter();

  React.useEffect(() => {
    router.push('/sign-in');
  }, []);

  return <div>HomePage</div>;
}
